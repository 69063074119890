$(document).ready(function () {
  $('input#priceRange').slider({});

  $('button#filter-toggle').click(function (e) {
    e.preventDefault();
    $('div#overlay').toggle();
    $('div.filter').fadeToggle();
  });

  $('div#overlay').click(function (e) {
    $('div#overlay').toggle();
    $('div.filter').fadeToggle();
  });

  $('button#filter').click(function (e) {
    e.preventDefault();
    var colors = [];
    $("input[name='colors[]']:checked").each(function (i) {
      colors[i] = $(this).val();
    });
    data = {
      price: $('input#priceRange').val(),
      colors: colors,
      order: $("select[name='order']").val()
    };
    $('div#products').hide();
    $('div#filtering').show();
    path = location.pathname.split("/");
    url = location.origin + "/" + path[1] + "/" + path[2] + "/" + path[3];
    $.post(url + '/filter', data)
        .success(function (res) {
            $('div#products').html(res);
            $('div#filtering').hide();
            $('div#products').show();
            // NOTE Hide the filter, once we have results back
            $('div#overlay').toggle();
            $('div.filter').fadeToggle();
        })
        .error(function (res) {
          alert('Възникна грешка при обработката на заявката Ви');
        });
  })
});

